import React from 'react'
import LayoutBs from '../../components/LayoutBs'
import Header from '../../components/Header/header'
import Footer from '../../components/Footer/footer'
import {references} from '../../components/strings'
import csi from "../../images/partners/csi.png"
import adac from "../../images/partners/adventure_academy.png"
import coche_box from "../../images/partners/coche_box.png"
import akern from "../../images/partners/akern.png"
import trgovir from "../../images/partners/trgovir.png"
import swiss from "../../images/partners/swiss_jewellery.png"
import keka from "../../images/partners/keka.png"
import jedra from "../../images/partners/jedra.png"
import divan from "../../images/partners/divan.png"
import aida_commerce from "../../images/partners/aida_commerce.png"
import bauwach from "../../images/partners/bauwach.png"
import gradiz from "../../images/partners/gradiz.png"
import hl_group from "../../images/partners/hl_group.png"
import kupitehniku from "../../images/partners/kupitehniku.png"
import labconsulting from "../../images/partners/labconsulting.png"
import modroizeleno from "../../images/partners/modroizeleno.png"
import msalem from "../../images/partners/msalem.png"
import msinstallation from "../../images/partners/msinstallation.png"
import photoschweda from "../../images/partners/photoschweda.png"
import portanaturae from "../../images/partners/portanaturae.png"
import vif from "../../images/partners/vif.png"
import vipcars from "../../images/partners/vipcars.png"
import zeljo from "../../images/partners/zeljo.png"
import holzart from "../../images/partners/holzart.png"
import sarayresidence from "../../images/partners/sarayresidence.png"
import bellona from "../../images/partners/bellona.png"
import luxor from "../../images/partners/luxor.png"
import kanzlei_smajic from "../../images/partners/kanzlei_smajic.png"
import disti from "../../images/partners/disti.png"
import dell from "../../images/partners/dell.png"
import AOS from 'aos';

class ReferencesPageBs extends React.Component {
    componentDidMount(){
        AOS.init({
          duration : 500
        })
      }
  render() {

    return (
      <LayoutBs>
          <div id="references">
          <Header prefix_string_page="bs"/>
            <div data-aos='fade-zoom-in'>
            
            <section id="p_references" className="section pages">
            <div className="overlay_image"></div>
            <div className="overlay_color"></div>
            <div className="container">        
       
                <h2 className="title">{references}</h2>

                <div className="content">
<h3>ONI NAM VJERUJU!</h3>

<div className="columns is-multiline consulting-clients">
    <div className="column is-2">
        <a href="https://akern.at/" target="_blank"><img src={akern} alt="Akern" /></a>
    </div>

    <div className="column is-2">
        <a href="https://disti.ba/" target="_blank"><img src={disti} alt="Disti" /></a>
    </div>

    <div className="column is-2">
        <a href="https://dell.com/" target="_blank"><img src={dell} alt="Dell" /></a>
    </div>

    <div className="column is-2">
        <a href="http://cochebox.com/" target="_blank"><img src={coche_box} alt="Coche Box" /></a>
    </div>

    <div className="column is-2">
        <a href="http://photoschweda.at/" target="_blank"><img src={photoschweda} alt="Photoschweda" /></a>
    </div>

    <div className="column is-2">
        <a href="https://kupitehniku.ba/" target="_blank"><img src={kupitehniku} alt="Kupi Tehniku" /></a>
    </div>

    <div className="column is-2">
        <a href="http://csi.ba" target="_blank"><img src={csi} alt="CSI" /></a>
    </div>

    <div className="column is-2">
        <a href="https://adac.ba" target="_blank"><img src={adac} alt="Adventure Academy" /></a>
    </div>

    <div className="column is-2">
        <a href="http://trgovir.ba" target="_blank"><img src={trgovir} alt="Trgovir" /></a>
    </div>

     <div className="column is-2">
        <a href="http://bellona.ba" target="_blank"><img src={bellona} alt="Bellona Namještaj" /></a>
    </div>
    
    <div className="column is-2">
        <a href="http://sarayresidence.ba/" target="_blank"><img src={sarayresidence} alt="Saray Residence" /></a>
    </div>

    <div className="column is-2">
        <a href="http://keka.ba" target="_blank"><img src={keka} alt="Keka Nakit" /></a>
    </div>

    <div className="column is-2">
        <a href="http://jedra.ba" target="_blank"><img src={jedra} alt="Jedra Sarajeva" /></a>
    </div>

    <div className="column is-2">
        <a href="http://swissjewellery.ba" target="_blank"><img src={swiss} alt="Swiss Jewellery" /></a>
    </div>

    <div className="column is-2">
        <a href="htts://divan.ba" target="_blank"><img src={divan} alt="Divan Namještaj" /></a>
    </div>

    <div className="column is-2">
        <a href="https://modroizeleno.com/" target="_blank"><img src={modroizeleno} alt="Modro i Zeleno" /></a>
    </div>

    <div className="column is-2">
        <a href="https://aida-commerce.ba" target="_blank"><img src={aida_commerce} alt="Aida Commerce" /></a>
    </div>

    <div className="column is-2">
        <a href="http://gradiz.ba" target="_blank"><img src={gradiz} alt="Gradiz" /></a>
    </div>

    <div className="column is-2">
        <a href="http://www.labconsulting.at/" target="_blank"><img src={labconsulting} alt="Lab Consulting" /></a>
    </div>

    <div className="column is-2">
        <a href="http://hl-group.ba" target="_blank"><img src={hl_group} alt="HL Group" /></a>
    </div>

    <div className="column is-2">
        <a href="http://bauwach.com/" target="_blank"><img src={bauwach} alt="Bauwach" /></a>
    </div>
    <div className="column is-2">
        <a href="http://zeljo.at" target="_blank"><img src={zeljo} alt="Zeljo Brill Burek" /></a>
    </div>

    <div className="column is-2">
        <a href="http://luxor.ba" target="_blank"><img src={luxor} alt="Luxor" /></a>
    </div>

    <div className="column is-2">
        <a href="http://msinstallation.at" target="_blank"><img src={msinstallation} alt="MS Installation" /></a>
    </div>

    <div className="column is-2">
        <a href="http://vif.ba" target="_blank"><img src={vif} alt="VIF Namještaj" /></a>
    </div>

    <div className="column is-2">
        <a href="https://vipcars.ba" target="_blank"><img src={vipcars} alt="VIP Cars" /></a>
    </div>

    <div className="column is-2">
        <a href="http://massiveholzart.de" target="_blank"><img src={holzart} alt="Massive Holzart" /></a>
    </div>

    <div className="column is-2">
        <a href="http://portanaturae.com" target="_blank"><img src={portanaturae} alt="Portanaturae" /></a>
    </div>

    <div className="column is-2">
        <a href="http://msalem.ba" target="_blank"><img src={msalem} alt="MS Alem" /></a>
    </div>

     <div className="column is-2">
        <a href="http://kanzlei-smajic.de" target="_blank"><img src={kanzlei_smajic} alt="Kanzlei Smajić" /></a>
    </div>
    
</div>
                </div>
                </div>            
</section>
        </div>
        </div>
          <Footer/>
      </LayoutBs>
    )
  }
}


export default ReferencesPageBs